import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators, AbstractControl, UntypedFormGroup, AsyncValidatorFn, UntypedFormControl } from '@angular/forms';
import { AuthService, ImagesService } from '../../../core/api';
import { UsersService } from '../../../core/api/users/users.service';
import { ModalsService } from '../../../shared/modals/modals.service';
import { Router } from '@angular/router';
import { UserPreferencesService } from '../../../core/api/user-preferences/user-preferences.service';
import { UpdateUserProfileAction } from '../../../core/store/users/users.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/app-reducer';
import { ChangePasswordDialogComponent } from '../../change-password-dialog/change-password-dialog.component';
import { AccountSettingsService } from '../../../core/api/account-settings/accounts-settings.service';
import { MixPanelService } from '../../../core/api/mixpanel/mixpanel.service';
import { BidiService } from '../../../core/i18n/bidi.service';

@Component({
  selector: 'gd-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  userId;
  accountId;
  userEdited: any = {id: 1};
  userForm: UntypedFormGroup;

  usedUsernames = new Set();
  mediaBaseUrl = this.accountSettingsService.getMediaBaseUrl();
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  get emailControl() {
    return <UntypedFormControl>this.userForm.get('email');
  }

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private userService: UsersService,
    private modalsService: ModalsService,
    private store: Store<AppState>,
    private router: Router,
    private userPreferenceService: UserPreferencesService,
    private usersService: UsersService,
    private accountSettingsService: AccountSettingsService,
    private imagesService: ImagesService,
    private mixPanelService: MixPanelService,
    private bidiService: BidiService
  ) { }

  ngOnInit() {
    this.userId = this.authService.getUserId();
    // prevent superadmin from accessing my-profile
    if (this.userId === 1) {
      this.router.navigate(['/dashboard']);
    }
    this.accountId = this.authService.getUserAccountId();
    this.initForm();
    this.userService
      .getUserProfile(this.userId)
      .toPromise()
      .then((userData) => {
        this.userEdited = userData;
        this.userEdited['password'] = '**********';
        this.userEdited['confirmPassword'] = '**********';
        this.userEdited['accountId'] = this.accountId;
        this.patchFormControls();
      });

    this.mixPanelService.trackEvent('PageLoaded', {
      page_type: 'MyProfilePage',
    });
  }

  initForm() {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(.+)$/;
    this.userForm = this.fb.group({
      'username': ['', Validators.compose([Validators.required]), this.validateUsernameOrEmail('username')],
      'email': ['', Validators.compose([Validators.required, Validators.email]), this.emailValidator.bind(this)],
      'firstName': ['', Validators.required],
      'lastName': ['', Validators.required],
      'password': ['', [Validators.required, Validators.minLength(10), Validators.pattern(passwordPattern)]],
      'confirmPassword': ['', [Validators.required]],
      'image': [''],
    }, { validator: userFormValidator });
  }

  patchFormControls() {
    const image = this.userEdited.image || null;
    if (image && image.path) {
      image.url = this.imagesService.generateImageUrl(image.path);
    }
    const user = { ...this.userEdited, image };
    Object.entries(user).forEach(entry => {
      const [propKey, propValue] = entry;
      const formControl = this.userForm.get(propKey);
      if ((propKey === 'username' || propKey === 'password') && formControl) {
        formControl.disable();
      }
      if (formControl) {
        formControl.patchValue(propValue);
      }
    });
  }

  openChangePasswordDialog() {
    const config = {
      data: {
       userData: this.userEdited,
       isProfilePage : true
      },
      width: '600px'
    };
    this.modalsService.custom($localize`Change Password`, ChangePasswordDialogComponent, config);
  }

  submitMyProfile() {
    const previousUrlPathValue = this.userPreferenceService.getUserPreference('currentUrlPathValue');
    const urlPathValue = previousUrlPathValue ? previousUrlPathValue : '/dashboard';
    if (this.userForm.dirty) {
      const { image } = this.userForm.value;
      if (image) {
        image.path = image.url.replace(this.mediaBaseUrl, '');
        delete image.url;
      }
      const userFormData = {
        ...this.userEdited,
        ...this.userForm.value,
        image,
      };
      userFormData.password = null;
      this.store.dispatch(new UpdateUserProfileAction({ userId: this.userId, userData: userFormData }));
      return;
    }
    this.router.navigate([urlPathValue]);
  }


  async getUsersUsernameAndEmail() {
    const data = {
      usernames: new Set(),
      emails: new Set()
    };

    if (this.usedUsernames.size === 0) {
      const usersResponse = await this.usersService.getUsers({}).toPromise();
      const activeUsers = Object.entries(usersResponse).map(([key, value]: any) => value);

      const deactivateUsersResponse = await this.usersService.getDeactivatedUsers().toPromise();
      const deactivateUsers = Object.entries(deactivateUsersResponse).map(([key, value]: any) => value);
      const users = activeUsers.concat(deactivateUsers);

      users.forEach(user => {
        this.usedUsernames.add(user.username);
      });
    }
    data.usernames = this.usedUsernames;
    return data;
  }

  validateUsernameOrEmail(key): AsyncValidatorFn {
    return (controlInput: AbstractControl): Promise<{ [key: string]: any } | null> => {
      if (controlInput.dirty && controlInput.value) {
        return new Promise(async resolve => {
          const data = await this.getUsersUsernameAndEmail();
          const dataSource = data.usernames;
          if (dataSource.has(controlInput.value)) {
            return resolve({ 'alreadyExist': true });
          }
          resolve(null);
        });
      }
      return Promise.resolve(null);
    };
  }

  emailValidator(emailControl: AbstractControl): { [key: string]: any | null } {
    return this.usersService.getUserByEmail(emailControl.value).toPromise()
      .then(() => {
        if (emailControl.value !== this.userEdited.email) {
          return ({ alreadyExist: true });
        }
      })
      .catch(() => null);
  }
}

function userFormValidator(form: AbstractControl): { [key: string]: any | null } {
  const passwordControl = form.get('password');
  const confirmPasswordControl = form.get('confirmPassword');

  if (passwordControl.value !== confirmPasswordControl.value) {
    confirmPasswordControl.setErrors({ match: true });
    return { match: true };
  }
  confirmPasswordControl.setErrors(null);
  return null;
}
