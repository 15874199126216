import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BidiService } from '../../core/i18n/bidi.service';

export enum ContentType {
  ARTICLE = 'ARTICLE',
  LR_POST = 'LR_POST',
  COLLECTION = 'COLLECTION'
}

@Component({
  selector: 'gd-froala-embed-widget',
  templateUrl: './froala-embed-widget.component.html',
  styleUrls: ['./froala-embed-widget.component.scss'],
})
export class FroalaEmbedWidgetComponent implements OnInit {
  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    title: [''],
  });

  widgetId: number;
  contentType: ContentType = ContentType.ARTICLE;
  is3rdPartyWidget: boolean = false;
  blockId: string;
  dialogInputDataConfiguration = {};

  // i18n directionality
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<FroalaEmbedWidgetComponent>,
    private formBuilder: UntypedFormBuilder,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.widgetId = this.dialogInputData.id;
    this.contentType = this.dialogInputData.glideContentType;
    this.is3rdPartyWidget = this.dialogInputData.embedType.split('/')[1] === 'third-party';
    this.blockId = this.dialogInputData.blockId;
    this.dialogInputDataConfiguration = this.dialogInputData.configuration;
    this.embeddableForm.patchValue(this.dialogInputData);
  }

  save() {
    const formModel = this.embeddableForm.value;
    const data = {
      type: this.dialogInputData.embedType,
      blockId: this.blockId,
      updateData: formModel,
    };
    this.dialogRef.close(data);
    return;
  }

  getSaveButtonTooltipMessage() {
    if (!this.embeddableForm.get('id').value) {
      return '';
    }
    if (this.isCustomDataTaxonomyAllowed()) {
      return $localize`Some of the used Taxonomies on custom fields are invalid.`;
    }
    return $localize`Widget custom data form is invalid`
  }

  isCustomDataTaxonomyAllowed() {
    const configuration = this.embeddableForm.get('configuration') as UntypedFormControl;
    if (!configuration) {
      return false;
    }
    return Object.values(configuration['controls'])
      .some((control: UntypedFormControl) => control.hasError("taxonomyErr"))
  }
}
