import { UnsafeAction } from '../unsafe-action.interface';

export const GET_IMAGES = '[Images] Get images';
export class GetImagesAction implements UnsafeAction {
  readonly type = GET_IMAGES;
  constructor(public payload: any) { }
}

export const GET_IMAGES_COMPLETE = '[Images] Get images completed';
export class GetImagesCompleteAction implements UnsafeAction {
  readonly type = GET_IMAGES_COMPLETE;
  constructor(public payload: any) { }
}

export const GET_TAGS = '[Images] Get images tags';
export class GetTagsAction implements UnsafeAction {
  readonly type = GET_TAGS;
  constructor(public payload = {}) { }
}

export const GET_TAGS_COMPLETE = '[Images] Get images tags completed';
export class GetTagsCompleteAction implements UnsafeAction {
  readonly type = GET_TAGS_COMPLETE;
  constructor(public payload: any) { }
}

export const CLEAR_TAGS_ACTION = '[Images] Clear image tags';
export class ClearTagsAction implements UnsafeAction {
  readonly type = CLEAR_TAGS_ACTION;
  constructor() { }
}

export const UPDATE_IMAGE_DETAILS = '[Images] Update image details';
export class UpdateImageDetailsAction implements UnsafeAction {
  readonly type = UPDATE_IMAGE_DETAILS;
  constructor(public payload: any) { }
}

export const UPDATE_IMAGE_DETAILS_COMPLETE = '[Images] Update image details completed';
export class UpdateImageDetailsCompletedAction implements UnsafeAction {
  readonly type = UPDATE_IMAGE_DETAILS_COMPLETE;
  constructor(public payload: any) { }
}

export const DELETE_IMAGE = '[Images] Delete image';
export class DeleteImageAction implements UnsafeAction {
  readonly type = DELETE_IMAGE;
  constructor(public payload: any) { }
}

export const SET_SELECTED_IMAGE = '[Images] Set selected image';
export class SetSelectedImageAction implements UnsafeAction {
  readonly type = SET_SELECTED_IMAGE;
  constructor(public payload: any) { }
}

export const SET_PAGE_VIEW_OPTIONS = '[Images] Set page view options';
export class SetPageViewOptions implements UnsafeAction {
  readonly type = SET_PAGE_VIEW_OPTIONS;
  constructor(public payload: any) { }
}

export const GET_IMAGES_FAILED = '[Images] Get images failed';
export class GetImagesFailedAction implements UnsafeAction {
  readonly type = GET_IMAGES_FAILED;
  // payload is the error
  constructor(public payload: any) { }
}

export const IMAGES_ACTION_FAILED = '[Images] Generic failure action';
export class ImagesFailedAction implements UnsafeAction {
  readonly type = IMAGES_ACTION_FAILED;
  constructor(public payload: any) { }
}

export const CLEAR_IMAGES_STATE = '[Images] Clear images state';
export class ClearImagesStateAction implements UnsafeAction {
  readonly type = CLEAR_IMAGES_STATE;
  constructor() { }
}

export const RESET_IMAGES_STATE = '[Images] Reset images state';
export class ResetImagesStateAction implements UnsafeAction {
  readonly type = RESET_IMAGES_STATE;
  constructor() { }
}

export const SET_IMAGES_LOADING_FLAG = '[Images] Set images loading flag';
export class SetImagesLoadingFlagAction implements UnsafeAction {
  readonly type = SET_IMAGES_LOADING_FLAG;
  constructor(public payload: any) { }
}
