import { RestService } from '../rest.service';
import { Injectable } from '@angular/core';
import { TaxonomyConfiguration } from '../../store/taxonomy-configuration/taxonomy-configuration.model';
import { AccountSettingsService } from '../account-settings/accounts-settings.service';
import { getAccountSettings } from '../../store/account-settings/account-settings.reducer';
import { AppState } from '../../store/app-reducer';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class TaxonomyConfigurationService {
  constructor(
    private rest: RestService,
    private accountSettingsService: AccountSettingsService,
    private authService: AuthService,
    private store: Store<AppState>
  ) {}

  get() {
    return this.rest.get('taxonomy-configurations').pipe(
      map((response: any) => response.data[0]),
      tap((config) => this.saveTaxonomyPathFlagInStore(config.showTaxonomyPath))
    );
  }

  update(taxonomyConfiguration: TaxonomyConfiguration) {
    const id = taxonomyConfiguration.id;
    this.saveTaxonomyPathFlagInStore(taxonomyConfiguration.showTaxonomyPath);
    return this.rest
      .put(`taxonomy-configurations/${id}`, taxonomyConfiguration)
      .pipe(map((response: any) => response.data));
  }

  saveTaxonomyPathFlagInStore(showTaxonomyPath) {
    this.store
      .select(getAccountSettings)
      .pipe(take(1))
      .subscribe((settings) => {
        const accountId = this.authService.getUserAccountId();
        this.accountSettingsService.refreshAccountSettingsInStore(
          accountId,
          settings,
          showTaxonomyPath || false
        );
      });
  }
}
