    <div fxFlex="50%"
    style="margin: 0px auto 48px auto;">
  <form [formGroup]="userForm"
        fxLayout="column">
    <mat-form-field>
      <textarea matInput
                placeholder="Username"
                i18n-placeholder
                formControlName="username"
                required
                autocomplete="off"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                trim="blur"></textarea>
      <mat-error *ngIf="userForm.get('username').hasError('required')" i18n>
        Username is required
      </mat-error>
      <mat-error *ngIf="userForm.get('username').hasError('alreadyExist')" i18n>
        This username is already taken
      </mat-error>
      <mat-error *ngIf="userForm.get('username').hasError('maxlength')" i18n>
        Max length 175 characters.
      </mat-error>
    </mat-form-field>
    <mat-form-field [style.margin-bottom]="passwordControl.hasError('pattern') ? '30px' : null">
      <input matInput
             type="password"
             placeholder="Password"
             i18n-placeholder
             autocomplete="new-password"
             formControlName="password"
             required>
      <mat-icon matSuffix
                *ngIf="!userForm.get('password').disabled"
                class="question-icon"
                i18n-matTooltip
                matTooltip="Password must be at least 10 characters long and it must contain at least one uppercase letter, one lowercase letter, one numeric digit and one special character."
                matTooltipPosition="right"
                fontSet="fal"
                fontIcon="fa-question-circle"></mat-icon>
      <mat-error *ngIf="userForm.get('password').hasError('required')" i18n>
        Password is required.
      </mat-error>
      <mat-error *ngIf="userForm.get('password').hasError('minlength')" i18n>Password must be at least 10 characters long.</mat-error>
      <mat-error *ngIf="userForm.get('password').hasError('pattern')" i18n>
        Password must contain at least one uppercase letter, one lowercase letter, one numeric digit and one special character.
      </mat-error>
    </mat-form-field>
    <div class="gd-edit-user__action-buttons" *ngIf="editMode"
          fxLayout="row"
          fxLayoutAlign="space-between center">

        <button mat-raised-button
              [disabled]="!hasPasswordChangePermission || !userEdited.id"
              (click)="openChangePasswordDialog()" i18n>Change Password</button>
      </div>
    <mat-form-field *ngIf="!userEdited.id">
      <input matInput
             type="password"
             autocomplete="new-password"
             placeholder="Confirm Password"
             i18n-placeholder
             formControlName="confirmPassword">
      <mat-error i18n *ngIf="passwordControl.value !== confirmPasswordControl.value">Confirm password and password fields must match</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input matInput
            placeholder="Email"
            i18n-placeholder
            formControlName="email"
            required
            autocomplete="off"
            trim="blur">
      <mat-error *ngIf="userForm.get('email').hasError('required')" i18n>
        Email is required
      </mat-error>
      <mat-error *ngIf="userForm.get('email').hasError('email')" i18n>
        Invalid email address
      </mat-error>
      <mat-error *ngIf="userForm.get('email').hasError('alreadyExist')" i18n>
        This email address is already taken
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput
                placeholder="First Name"
                i18n-placeholder
                formControlName="firstName"
                required
                autocomplete="off"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                trim="blur"></textarea>
      <mat-error *ngIf="userForm.get('firstName').hasError('required')" i18n>
        First name is required
      </mat-error>
      <mat-error *ngIf="userForm.get('firstName').hasError('maxlength')" i18n>
        Max length 255 characters.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <textarea matInput
                placeholder="Last Name"
                i18n-placeholder
                formControlName="lastName"
                required
                autocomplete="off"
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                trim="blur"></textarea>
      <mat-error *ngIf="userForm.get('lastName').hasError('required')" i18n>
        Last name is required
      </mat-error>
      <mat-error *ngIf="userForm.get('lastName').hasError('maxlength')" i18n>
        Max length 255 characters.
      </mat-error>
    </mat-form-field>
    <div *ngIf="userForm.get('enablePasswordLogin')"
         style="margin-bottom: 10px;">
      <mat-slide-toggle formControlName="enablePasswordLogin"
                        i18n> Enable Password Login</mat-slide-toggle>
    </div>
    <gd-promo-image [formControl]="userForm.get('image')"
                    [promoType]="'icon'"
                    ngDefaultControl
                    [hasActionPermission]="true"
                    [customLabel]="'Image'"></gd-promo-image>
  </form>
</div>

