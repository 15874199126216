<div class="gd-froala-embed-widget"
     [dir]="dir$ | async">
  <h2 mat-dialog-title i18n="@@embedSystemOr3rdPartyWidget">Embed {is3rdPartyWidget, select, true {Third Party Widget} false {System Widget} }</h2>

  <gd-embed-widget-configuration [embeddableForm]="embeddableForm"
                                 [widgetId]="widgetId"
                                 [contentType]="contentType"
                                 [is3rdPartyWidget]="is3rdPartyWidget"
                                 [dialogInputDataConfiguration]="dialogInputDataConfiguration"
                                 [blockId]="blockId"
                                 [usage]="'ARTICLE'"
                                 [activeLocale]="dialogInputData.activeLocale">
  </gd-embed-widget-configuration>
  <section class="gd-embed-widget-dialog__section-buttons">
    <button mat-raised-button
            color="primary"
            type="submit"
            [disabled]="embeddableForm.invalid"
            (click)="save()">
      <span [matTooltip]="getSaveButtonTooltipMessage()"
            [matTooltipDisabled]="!embeddableForm.get('id').value || embeddableForm.valid"
            i18n>
        Save
      </span>
    </button>
    <button mat-raised-button
            color="danger"
            i18n
            mat-dialog-close>Cancel</button>
  </section>
</div>
