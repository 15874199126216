import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Permissions } from '../store/auth/permissions';
import { PermissionService } from '../api/auth/permissions.service';

@Injectable()
export class ItemFormPermissionsGuard implements CanActivate {

  // TODO move the mapping logic to permissions service
  urlMap = {
    articles: Permissions.GC_ARTICLE_WRITE,
    taxonomies: Permissions.GC_TAXONOMY_WRITE,
    pages: Permissions.GC_PAGE_WRITE,
    'page-routes': Permissions.GC_ROUTE_WRITE,
    templates: Permissions.GC_TEMPLATE_WRITE,
    roles: Permissions.GU_ROLE_CREATE,
    users: Permissions.GU_USER_CREATE,
    galleries: Permissions.GM_GALLERY_WRITE,
    authors: Permissions.GC_AUTHOR_WRITE,
    'article-types': Permissions.GC_ARTICLE_TYPE_WRITE,
    redirects: Permissions.GC_REDIRECT_WRITE,
    menus: Permissions.GC_MENU_WRITE,
    'html-snippets': Permissions.GC_HTML_SNIPPET_WRITE,
    'system-widgets': Permissions.GC_SYSTEM_WIDGET_WRITE,
    'third-party-widgets': Permissions.GC_3RD_PARTY_WIDGET_WRITE,
    'content-panels': Permissions.GC_CONTENT_PANEL_WRITE,
    'collection-types': Permissions.GC_COLLECTION_TYPE_WRITE,
    'collections': Permissions.GC_COLLECTION_WRITE,
    'content-tags': Permissions.GC_CONTENT_TAG_WRITE,
    'sso': Permissions.GU_SSO_UPDATE,
    'access-bundles': Permissions.GV_ACCESS_BUNDLE_WRITE,
    'verify-config': Permissions.GV_CONFIGURE_READ,
    'media-config': Permissions.GM_CONFIGURE_READ,
    'live-reporting': Permissions.GC_LIVE_REPORT_WRITE,
    'custom-field-groups': Permissions.GC_FIELD_GROUP_WRITE,
    'subscriber': Permissions.GT_SUBSCRIBER_WRITE,
    'content-locales' : Permissions.GC_CONTENT_LOCALE_WRITE,
    'live-report-types': Permissions.GC_LIVE_REPORT_TYPE_WRITE,
    //SN killSwitch 'notifications': Permissions.GC_SYSTEM_NOTIFICATION_CREATE,
    //SN killSwitch 'system-notifications': Permissions.GC_SYSTEM_NOTIFICATION_CREATE
  };

  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const urlArray = state.url.match(/[^?]*/)[0].split('/');
    const size = urlArray.length;

    // url params
    const section = urlArray[size - 2];
    let id: any = urlArray[size - 1];
    id = id === 'create' ? id : +id;

    // invalid id
    if (!id) {
      return this.redirectToIndexPage(urlArray);
    }

    const isEditingMode = id !== 'create';
    const hasCreatePermission = this.permissionService.hasPermission(this.urlMap[section]);

    return isEditingMode || hasCreatePermission ? true : this.redirectToIndexPage(urlArray);
  }

  redirectToIndexPage(urlArray) {
    urlArray.pop();
    this.router.navigate([`/${urlArray.join('/')}`]);
    return false;
  }

}
