<form [formGroup]="embeddableForm" (ngSubmit)="save()" novalidate>
<div class="gd-embed-dialog" fxLayout="column" fxLayoutAlign="space-between strech" [dir]="dir$ | async">

  <h2 mat-dialog-title>{{dialogInputData.title}}</h2>
    <div class="gd-embed-dialog__data-wrapper">
        <div>
            <section class="gd-embed-dialog__preview" [class.gd-embed-dialog__preview--not-ready]="!safeUrl">
              <iframe *ngIf="safeUrl" width="450" height="300" [src]="safeUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
            </section>
          </div>

        <div fxLayout="column" fxFlex="270px">
          <div>
            <section>
              <mat-form-field class="gd-embed-dialog__id-wrapper" fxFlex="100%" appearance="outline">
                <mat-label i18n="Youtube video id">Id</mat-label>
                <input matInput type="text" required formControlName="id" autocomplete="off">
                <mat-error *ngIf="embeddableForm.get('id').hasError('invalidVideoId')" i18n="Validation message">Invalid video ID</mat-error>
                <mat-error *ngIf="embeddableForm.get('id').hasError('required')" i18n="Validation message">Video ID is required</mat-error>
              </mat-form-field>
            </section>

            <section>
              <mat-form-field class="gd-embed-dialog__name-wrapper"
                              fxFlex="100%"
                              appearance="outline"
                              [floatLabel]="!!dialogInputFormData.name ? 'always' : 'never'">
                <mat-label i18n>Name</mat-label>
                <textarea matInput
                cdkTextareaAutosize
                type="text"
                formControlName="name"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="2"></textarea>
              </mat-form-field>
            </section>
            <section>
              <div class="gd-article-form__input">
                <mat-slide-toggle formControlName="autoplay">Autoplay</mat-slide-toggle>
              </div>
            </section>
          </div>
        </div>
    </div>

      <section class="gd-embed-dialog__description-wrapper">
        <mat-form-field class="gd-embed-dialog__description"
                        fxFlex="100%"
                        appearance="outline"
                        [floatLabel]="!!dialogInputFormData.description ? 'always': 'never'">
          <mat-label i18n>Description</mat-label>
          <textarea matInput
            cdkTextareaAutosize
            type="text"
            formControlName="description"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="7"></textarea>
        </mat-form-field>
    </section>

    <section class="gd-embed-dialog-buttons">
      <button mat-raised-button color="primary" [disabled]="embeddableForm.invalid || embeddableForm.pending"
        type="submit" class="gd-embed-dialog-buttons--save" i18n>Save</button>
      <button mat-raised-button color="danger" mat-dialog-close i18n>Cancel</button>
    </section>
</div>
</form>

