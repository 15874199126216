import {
  withLatestFrom,
  switchMap,
  tap,
  catchError,
  retry,
  map,
  mergeMap,
  filter,
} from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import {
  GET_IMAGES,
  GetImagesCompleteAction,
  UPDATE_IMAGE_DETAILS,
  UpdateImageDetailsCompletedAction,
  GET_TAGS,
  GetTagsCompleteAction,
  GetImagesFailedAction,
  DELETE_IMAGE,
  GetImagesAction,
  IMAGES_ACTION_FAILED,
  ImagesFailedAction,
} from './images.actions';
import { UnsafeAction } from '../unsafe-action.interface';
import { ImagesService } from '../../api/images/images.service';
import { AppState } from '../app-reducer';
import { Router } from '../../../../../node_modules/@angular/router';

@Injectable()
export class ImagesEffects {

  getImages$: Observable<Action> = createEffect(() => this.actions$.pipe(
    filter((action) => action.type === GET_IMAGES),
    mergeMap((action: UnsafeAction) => {
      return this.imagesService.getImages(action.payload).pipe(
        map((images) => new GetImagesCompleteAction(images)),
        retry(3),
        catchError((err) =>
          of(new GetImagesFailedAction((err && err.json && err.json().message) || err))
        )
      );
    })
  ));


  getTags$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(GET_TAGS),
    mergeMap((action: UnsafeAction) =>
      this.imagesService.getTags(action.payload).pipe(
        map((tags) => new GetTagsCompleteAction(tags)),
        catchError((e) => of(new ImagesFailedAction({ error: e, action })))
      )
    )
  ));


  updateImage$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(UPDATE_IMAGE_DETAILS)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.imagesService.updateImageDetails(action.payload).pipe(
        tap((response) => this.snackBar.open($localize`Image details saved`, $localize`Close`, { duration: 400 })),
        map((response) => new UpdateImageDetailsCompletedAction(action.payload)),
        tap(() => this.router.navigate(['/media/images'])),
        catchError((e) => of(new ImagesFailedAction({ error: e, action })))
      )
    )
  ));


  deleteImage$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(DELETE_IMAGE)).pipe(
    mergeMap((action: UnsafeAction) =>
      this.imagesService.deleteImage(action.payload.imageId).pipe(
        switchMap((response) => {
          const { pageIndex, pageSize } = action.payload.pageView;
          return this.imagesService.getImages({ page: pageIndex, size: pageSize }).pipe(
            map((images) => {
              return new GetImagesCompleteAction(images);
            }),
            catchError((e) => of(new ImagesFailedAction({ error: e, action })))
          );
        })
      )
    )
  ));


  actionFailed$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType(IMAGES_ACTION_FAILED)).pipe(
    tap((err: any) => {
      const actionType =
        (err && err.payload && err.payload.action && err.payload.action.type) || $localize`Unknown`;
      this.snackBar.open($localize`Action failed: ${actionType}:actionType:`, `Close`, { duration: 4000 });
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private imagesService: ImagesService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
}
