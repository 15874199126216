<div class="gd-collection-embed-widget" [dir]="dir$ | async">
  <div fxLayout="column"
       fxLayoutAlign="space-around strech">
    <h2 mat-dialog-title i18n="@@embedSystemOr3rdPartyWidget">Embed {is3rdPartyWidget, select, true {Third Party Widget} false {System Widget} }</h2>

    <mat-tab-group dynamicHeight [selectedIndex]="tabIndex">
      <mat-tab label="Configuration" i18n-label>
        <gd-embed-widget-configuration [embeddableForm]="embeddableForm"
                                       [widgetId]="widgetId"
                                       [contentType]="contentType"
                                       [is3rdPartyWidget]="is3rdPartyWidget"
                                       [dialogInputDataConfiguration]="dialogInputDataConfiguration"
                                       [allowedWidgetsIdsFromConfig]="allowedWidgetTypes"
                                       [usage]="'COLLECTION'">
        </gd-embed-widget-configuration>
      </mat-tab>
      <mat-tab label="Metadata" i18n-label>
        <section>
          <ng-container *ngIf="itemMetaDataFormConfig; else noItemConfigRequired">
            <gd-dynamic-widget-form [formConfig]="itemMetaDataFormConfig"
                                    [widgetFormGroup]="itemMetaDataControl"
                                    [initialValues]="initialValues"
                                    [isEditingMode]="isEditingMode"
                                    [usage]="'COLLECTION'"
                                    [twoColumnLayoutAllowed]="true">
            </gd-dynamic-widget-form>
          </ng-container>
          <ng-template #noItemConfigRequired>
            <div [style.margin-bottom]="'10px'" i18n>No configuration needed.</div>
          </ng-template>
        </section>
      </mat-tab>
    </mat-tab-group>
    <section class="gd-embed-widget-dialog__section-buttons">
      <button mat-raised-button
              color="primary"
              type="submit"
              [disabled]="embeddableForm.invalid"
              (click)="save()">
        <span [matTooltip]="getSaveButtonTooltipMessage()"
              [matTooltipDisabled]="!embeddableForm.get('id').value || embeddableForm.valid"
              i18n>
          Apply
        </span>
      </button>
      <button mat-raised-button
              color="danger"
              i18n
              mat-dialog-close>Cancel</button>
    </section>
  </div>
</div>
