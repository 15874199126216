import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { sortByLabelAsc } from '../../store';
import { of } from 'rxjs';
import { defaultPageSize } from '../../store/constants/default-pagination.constants';

export interface UsersQueryParameters {
  page?: number;
  size?: number;
  username?: string;
  email?: string;
  ids?: number[];
  roleIds?: number[];
  accountIds?: number[];
  search?: string;
}
@Injectable()
export class UsersService {

  restParams = {
    service: 'glideUsers',
  };

  predefinedTaxonomyPermissions = { allowAll: 0 };

  constructor(private rest: RestService) { }

  getAccounts(options = {transformResponseToMap: true}) {
    return this.rest.get('accounts', this.restParams).pipe(
      map(response => {
        if(options.transformResponseToMap) {
          return arrayToMap(response.data);
        }
        return response.data;
      }));
  }

  getAccountsForUser(userId) {
    return this.rest.get(`accounts/user/${userId}`, this.restParams).pipe(
      map(response => response.data));
  }

  getAccount(id) {
    return this.rest.get(`accounts/${id}`, this.restParams).pipe(
      map(res => res.data));
  }

  createAccount(payload) {
    return this.rest.post('accounts', payload, this.restParams);
  }

  updateAccount(payload) {
    const { accountId, accountPayload } = payload;
    return this.rest.put(`accounts/${accountId}`, accountPayload, this.restParams);
  }

  deleteAccount(id) {
    return this.rest.delete(`accounts/${id}`, this.restParams);
  }

  getRoles() {
    return this.rest.get('roles', this.restParams).pipe(
      map(response => arrayToMap(response.data)));
  }

  getRole(id) {
    return this.rest.get(`roles/${id}`, this.restParams).pipe(
      map(response => response.data));
  }

  createRole(payload) {
    return this.rest.post('roles', payload, this.restParams);
  }

  updateRole(payload) {
    const { roleId, role } = payload;
    return this.rest.put(`roles/${roleId}`, role, this.restParams);
  }

  getPermissions() {
    return this.rest.get('permissions', this.restParams).pipe(
      map(({ data }) => {
        return {
          sectionPermissions: makePermissionsMap(data.sectionPermissions),
          taxonomyPermissions: data.predefinedTaxonomyPermissions,
          fieldPermissions: data.fieldPermissions || [],
        };
      }));
  }

  deleteRole(id) {
    return this.rest.delete(`roles/${id}`, this.restParams);
  }

  getUsers({page, size , username, email, ids, roleIds, accountIds, search}: UsersQueryParameters) {
    size = size || defaultPageSize;
    if ((ids || []).length > size) {
      size = ids.length;
    }
    let requestPath = `users?page=${page || 0}&size=${size}`;
    requestPath += (username || '').length ? `&username=${encodeURIComponent(username)}` : '';
    requestPath += (email || '').length ? `&email=${encodeURIComponent(email)}` : '';
    requestPath += (ids || []).length ? `&ids=${ids.join(',')}` : '';
    requestPath += (roleIds || []).length ? `&roleIds=${roleIds.join(',')}` : '';
    requestPath += (accountIds || []).length ? `&accountIds=${accountIds.join(',')}` : '';
    requestPath += (search || '').length ? `&search=${search}` : '';

    return this.rest.get(requestPath, this.restParams)
  }

  getUsersBasicProjection({page, size, ids }: UsersQueryParameters) {
    size = size || defaultPageSize;
    if ((ids || []).length > size) {
      size = ids.length;
    }
    let requestPath = `users/basic-projection?page=${page || 0}&size=${size}`;
    requestPath += (ids || []).length ? `&ids=${ids.join(',')}` : '';

    return this.rest.get(requestPath, this.restParams)
  }

  getUser(id) {
    return this.rest.get(`users/${id}`, this.restParams).pipe(
      map(response => response.data));
  }

  createUser(payload) {
    return this.rest.post('users', payload, this.restParams);
  }

  updateUser(payload) {
    const { userId, user } = payload;
    return this.rest.put(`users/${userId}`, user, this.restParams);
  }

  deactivateUser(payload) {
    const { userId, user } = payload;
    return this.rest.put(`users/${userId}/deactivate`, user, this.restParams);
  }

  changeUserPassword({ userId, user, isProfilePage }) {
    const requestEndPoint = isProfilePage ? `users/${userId}/profile` : `users/${userId}/password`;
    return this.rest.put(requestEndPoint, user, this.restParams);
  }

  getDeactivatedUsers() {
    return this.rest.get('users/deactivated', this.restParams).pipe(
      map(response => arrayToMap(response.data)));
  }

  getUserProfile(id) {
    if (id === 1) {
      return of({});
    }

    return this.rest.get(`users/${id}/profile`, this.restParams).pipe(
      map(response => response.data));
  }

  getBasicUserProfile(id) {
    if (id === 1) {
      return of({});
    }

    return this.rest.get(`users/${id}/profile/basic-projection`, this.restParams)
      .pipe(map(response => response.data));
  }

  updateUserProfile(payload) {
    const { userId, userData } = payload;
    return this.rest.put(`users/${userId}/profile`, userData, this.restParams);
  }

  getUsersByIds(ids) {
    return this.rest.get(`users?ids=${ids.join(',')}`, this.restParams).pipe(map(response => response.data));
  }

  getUserByUsername(username) {
    return this.rest.get(`users?username=${username}`, this.restParams).pipe(
      map(response => response.data));
  }

  getConnectApiKey() {
    return this.rest.get(`keys/connect-key`, this.restParams).pipe(
      map(response => response.data));
  }

  getRoleTypes() {
    return this.rest.get('roles/types', this.restParams).pipe(map(response => response.data));
  }

  getPredefinedTaxonomyPermissions() {
    return this.predefinedTaxonomyPermissions;
  }

  getUserByEmail(email) {
    return this.rest.get(`users?email=${email}`, this.restParams).pipe(
      map(response => response.data));
  }

  checkIfUserExists(payload) {
    return this.rest.post('users/exists', payload, this.restParams).pipe(
      map(response => response.data));
  }
}

export function makePermissionsMap(permissionsData) {
  // Group permissions by module
  let groupedPermissions = permissionsData.map((rawPermission) => {
    const reg = /^([^_]+)_(.*)_([^_]+)$/i;
    const matched = rawPermission.name.match(reg);
    const [module, section, action] = [matched[1], matched[2], matched[3]];
    const permission = { id: rawPermission.id, module, section, action, displayLabel: rawPermission.action };
    return permission;
  }).reduce((acc, permission) => ({
    ...acc,
    [permission.module]: [...acc[permission.module] || [], permission]
  }), {});

  // Group permissions by section
  groupedPermissions = Object.entries(groupedPermissions).reduce((acc, permissions) => {
    const [permissionsModule] = permissions;
    const modulePermissions = <Array<any>>permissions[1];

    const sectionPermissions = modulePermissions.reduce((sectionPermissionsAcc, permission) => {
      const sectionPermission = {
        id: permission.id,
        label: permission.action,
        displayLabel: permission.displayLabel,
        fullLabel: `${permission.module}_${permission.section}_${permission.action}`,
        module: permission.module,
        section: permission.section,
        type: 'action'
      };

      return {
        ...sectionPermissionsAcc,
        [permission.section]: [...sectionPermissionsAcc[permission.section] || [], sectionPermission]
      };
    }, {});

    return { ...acc, [permissionsModule]: sectionPermissions };
  }, {});

  // Organize permissions by sections
  // Return array of permissions modules
  groupedPermissions = Object.entries(groupedPermissions).map((permissionsModule) => {
    const [moduleName, modulePermissions] = permissionsModule;
    const { moduleLabel, moduleColor } = resolveModuleLabels(moduleName);
    const sectionPermissions = Object.entries(modulePermissions).map(section => ({
      label: section[0],
      displayLabel: prepareSectionLabel(section[0]),
      fullLabel: `${moduleName}_${section[0]}`,
      type: 'section',
      module: moduleName,
      permissions: [...section[1]]
    }));

    return {
      label: moduleName,
      fullLabel: moduleLabel,
      displayLabel: moduleLabel,
      type: 'module',
      color: moduleColor,
      sections: [...sectionPermissions],
    };
  });

  groupedPermissions
    .sort((a, b) => sortByLabelAsc(a, b))
    .forEach(element => element.sections.sort((a, b) => sortByLabelAsc(a, b)));

  return {
    permissions: groupedPermissions,
    rawPermissions: permissionsData
  };
}

const resolveModuleLabels = (moduleName) => {
  switch (moduleName) {

    case 'GC':
      return { moduleLabel: 'Glide Create', moduleColor: '#D80073' };
    case 'GM':
      return { moduleLabel: 'Glide Media', moduleColor: '#0288D1' };
    case 'GU':
      return { moduleLabel: 'Glide Users', moduleColor: '#009688' };
    case 'GE':
      return { moduleLabel: 'Glide Enrich', moduleColor: '#009688' };
    case 'GV':
      return { moduleLabel: 'Glide Verify', moduleColor: '#de350b' };
    case 'GT':
      return { moduleLabel: 'Glide Transmit', moduleColor: '#C8720F' };
    default:
      return;
  }
};

function prepareSectionLabel(label) {
  label = label.replace(/_/g, ' ');
  return label
    .split(' ')
    .map(str => str.toLowerCase())
    .map(str => getFirstLetterCapitalized(str)).join(' ');
}

export function getFirstLetterCapitalized(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const arrayToMap = (data) => data.reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
