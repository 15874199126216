<form fxLayout="column" fxLayoutAlign="space-around strech" [formGroup]="embeddableForm" (ngSubmit)="save()" novalidate>
  <div class="gd-embed-vimeo-dialog" fxLayout="column" fxLayoutAlign="space-between strech" [dir]="dir$ | async">
    <h2 mat-dialog-title i18n="Modal headline">Embed Vimeo Video</h2>

    <div class="gd-embed-vimeo-dialog__data-wrapper">
      <div>
        <section class="gd-embed-vimeo-dialog__preview"
          [class.--bg-black]="isValidVideoId"
          id="video-preview"></section>
      </div>

      <div fxLayout="column" fxFlex="270px">
        <div>
          <section>
            <mat-form-field class="gd-embed-vimeo-dialog__id-wrapper" fxFlex="100%" appearance="outline">
              <mat-label i18n>Id</mat-label>
              <input matInput type="text" required formControlName="id" autocomplete="off">
              <mat-error *ngIf="idControl.hasError('invalidVideoId')" i18n="Validation message">Invalid video ID</mat-error>
              <mat-error *ngIf="idControl.hasError('required')" i18n="Validation message">Video ID is required</mat-error>
            </mat-form-field>
          </section>

          <section>
            <mat-form-field class="gd-embed-vimeo-dialog__name-wrapper"
                            fxFlex="100%"
                            appearance="outline"
                            [floatLabel]="!!dialogInputFormData.name ? 'always' : 'never'">
              <textarea matInput
                        cdkTextareaAutosize
                        type="text"
                        [placeholder]="dialogInputFormData.name"
                        formControlName="name"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="2"></textarea>
              <mat-error *ngIf="embeddableForm.get('name').hasError('required')" i18n="Validation message">Name is required</mat-error>
            </mat-form-field>
          </section>

          <section>
            <div class="gd-article-form__input">
              <mat-slide-toggle formControlName="autoplay" i18n>Autoplay</mat-slide-toggle>
            </div>
          </section>
        </div>
      </div>
      </div>

      <section class="gd-embed-vimeo-dialog__description-wrapper">
          <mat-form-field class="gd-embed-vimeo-dialog__description"
                          fxFlex="100%"
                          appearance="outline"
                          [floatLabel]="!!dialogInputFormData.description ? 'always' : 'never'">
            <mat-label i18n>Description</mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      type="text"
                      [placeholder]="dialogInputFormData.description"
                      formControlName="description"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="7"></textarea>
            </mat-form-field>
        </section>

  <section class="gd-embed-vimeo-dialog-buttons">
    <button mat-raised-button color="primary" [disabled]="embeddableForm.invalid || embeddableForm.pending"
      type="submit" class="gd-embed-vimeo-dialog-buttons--save" i18n>Save</button>
    <button mat-raised-button color="danger" mat-dialog-close i18n>Cancel</button>
  </section>

  </div>
</form>
