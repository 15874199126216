<div fxLayout="column"
     fxLayoutAlign="stretch space-between"
     fxLayoutGap="12px"
     class="gm-upload-image-meta-card"
     [style.padding]="isStandalone ? '18px' : '0px 18px'"
     [dir]="dir$ | async">

  <div class="gm-upload-image-meta-card__columns"
       fxLayout="row"
       [style.height]="(usage === 'article' && !editingMode && images?.length > 1) ? '75%' : '100%'">
    <div class="gd-upload-image-meta-card__image-preview"
         style="width: 55%"
         [style.max-height]="editingMode ? '50%' : '55%'">
      <img [src]="image.previewImage || (image.dataURL | sanitazeURL) || image.thumbnail"
           [style.margin-bottom]="usage === 'image' && '10px'"
           [class.--small-image]="usage === 'article' && isImageSmallDimensions">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <ng-container>
              <div style="display: flex; flex-direction: column;"
                   *ngIf="imageMetaData?.ai_service === 'Amazon Bedrock'">
                <span class="gm-upload-image-meta-card__bedrock-image-info-title"
                      i18n>Originator details</span>
                <span class="gm-upload-image-meta-card__bedrock-image-info"><ng-container i18n>AI Service</ng-container>:
                  {{imageMetaData?.ai_service}}</span>
                <span class="gm-upload-image-meta-card__bedrock-image-info"><ng-container i18n>Foundation Model</ng-container>:
                  {{imageMetaData?.foundation_model}}</span>
                <span class="gm-upload-image-meta-card__bedrock-image-info"><ng-container i18n>Prompt Author</ng-container>:
                  {{imageMetaData?.ai_author}}</span>
                <span class="gm-upload-image-meta-card__bedrock-image-info-title --configuration"
                      i18n>Inference Configuration Values</span>
                <span class="gm-upload-image-meta-card__bedrock-image-info"><ng-container i18n>Prompt strength</ng-container>:
                  {{imageMetaData?.prompt_strength}}</span>
                <span class="gm-upload-image-meta-card__bedrock-image-info"><ng-container i18n>Seed</ng-container>:
                  {{imageMetaData?.seed}}</span>
                <span class="gm-upload-image-meta-card__bedrock-image-info"
                      *ngIf="imageMetaData?.generation_step"><ng-container i18n>Generation step</ng-container>:
                  {{imageMetaData?.generation_step}}</span>
                  <span class="gm-upload-image-meta-card__bedrock-image-info"
                        *ngIf="imageMetaData?.style_preset"><ng-container i18n>Style Preset</ng-container>:
                    {{formatStylePreset(imageMetaData?.style_preset)}}</span>
                  <span class="gm-upload-image-meta-card__bedrock-image-info-title --prompt-and-copyright"
                        i18n>Used Prompt</span>
                  <span class="gm-upload-image-meta-card__bedrock-image-info image-prompt">
                    <ng-container i18n>Positive Prompt</ng-container>: {{imageMetaData?.ai_generated_image_prompt}} </span>
                  <span class="gm-upload-image-meta-card__bedrock-image-info image-prompt"
                        *ngIf="imageMetaData?.negative_prompt"><ng-container i18n>Negative Prompt</ng-container>:
                    {{imageMetaData?.negative_prompt}} </span>
                <span class="gm-upload-image-meta-card__bedrock-image-info-title --prompt-and-copyright"><ng-container
                                i18n>Copyright</ng-container>:
                  <span style="font-weight: normal;"
                        class="gm-upload-image-meta-card__bedrock-image-info">{{imageMetaData?.copyright}} </span>
                </span>
              </div>
            </ng-container>
          <span class="gd-upload-image-meta-card__btn-crop"
                [class.gd-upload-image-meta-card__btn-crop--disabled]="(hasCroppableRenditions$ | async) !== true"
                *ngIf="image?.queueID && !dialogInputData?.data?.image"
                matTooltip="There are currently no renditions available for Manual Crop. This can be configured in the 'Media > Configure' section"
                i18n-matTooltip
                [matTooltipDisabled]="hasCroppableRenditions$ | async"
                (click)="triggerCroppingProcess()">
              <mat-icon style="margin-right: 6px">crop</mat-icon> <span i18n>Manual Crop</span>
          </span>
          </div>
           <ng-container *ngFor="let tag of imageTags">
            <mat-chip class="gm-upload-image-chip" *ngIf="tag?.premium">
              <span class="gm-upload-image-chip__text"
                [matTooltip]="tag.name"
                [matTooltipShowDelay]="200"
              >{{tag.name}}</span>
            </mat-chip>
          </ng-container>
          <mat-progress-spinner *ngIf="image.progress"
                                color="accent"
                                diameter="55"
                                mode="determinate"
                                [value]="image.progress">
          </mat-progress-spinner>
    </div>

    <mat-divider vertical="true"
                 style="margin-left: 8px; margin-right: 8px;">
    </mat-divider>

    <div class="gm-upload-image-meta__details-wrapper"
         style="width: 45%"
         fxLayout="column">

      <!-- <mat-tab-group class="gd-upload-image-meta__tabs"
                     mat-align-tabs="center"
                     (selectedTabChange)="handleTabChanged($event)">

        <mat-tab label="Image"> -->
            <div class="gd-upload-image-meta__scroller">
              <span class="gd-upload-image-meta__new-tab-btn"
                    (click)="navigateToItem()"
                    *ngIf="!image?.queueID && image.id">
                <mat-icon class="gd-upload-image-meta__new-tab-icon"
                          matTooltip="Open in new tab"
                          i18n-matTooltip
                          matTooltipPosition="below">open_in_new</mat-icon>
              </span>
              <form fxLayout="column"
                    class="gm-upload-image-meta__form"
                    [formGroup]="imageMetaForm">

                <mat-form-field fxFlex="100%"
                                appearance="outline">
                  <mat-label i18n="imageCaption|">Caption</mat-label>
                  <textarea class="gd-upload-image-meta__caption-field"
                            matInput
                            cdkTextareaAutosize
                            data-cy="gd-upload-image-meta__caption-field"
                            #autosize="cdkTextareaAutosize"
                            formControlName="caption"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="8"></textarea>
                </mat-form-field>

                <!-- Credit as simple text input -->
                <mat-form-field *ngIf="usage === 'gallery' || usage === 'article'"
                                fxFlex="100%"
                                appearance="outline">
                  <mat-label  i18n="imageCredit|">Credit</mat-label>
                  <input matInput
                         [formControl]="embeddedImageCredit"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field fxFlex="100%"
                                appearance="outline"
                                *ngIf="usage === 'image'">
                  <mat-label i18n="imageHeadline|">Headline</mat-label>
                  <input matInput
                         formControlName="headline"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field fxFlex="100%"
                                appearance="outline"
                                [floatLabel]="!!captionControl.value ? 'always' : 'never'">
                  <mat-label i18n="imageAltAttribute">Alt</mat-label>
                  <input matInput
                         formControlName="alt"
                         (blur)="handleAltFieldOnBlur()"
                         autocomplete="off">
                </mat-form-field>

                <section *ngIf="usage === 'image' || usage === 'article' || usage === 'gallery'">
                  <mat-form-field fxFlex="100%"
                                  appearance="outline">
                    <mat-label i18n>Description</mat-label>
                    <textarea class="gd-upload-image-meta__caption-field"
                              matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              formControlName="description"
                              cdkAutosizeMinRows="1"
                              cdkAutosizeMaxRows="4"></textarea>
                  </mat-form-field>
                </section>

                <mat-form-field fxFlex="100%"
                                appearance="outline"
                                *ngIf="usage === 'image' || usage === 'article'">
                  <mat-label i18n="imageCopyright|">Copyright</mat-label>
                  <input matInput
                         formControlName="copyright"
                         autocomplete="off">
                </mat-form-field>

                <mat-form-field fxFlex="100%"
                                appearance="outline"
                                *ngIf="usage === 'article'">
                    <mat-label i18n="A cue for image rendering, could be used to chose image renditions">Image Format</mat-label>
                    <mat-select formControlName="imageFormat">
                      <mat-option *ngFor="let format of articleImageFormats" [value]="format.value">
                        {{format.viewValue}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="100%"
                                appearance="outline"
                                *ngIf="usage === 'article'">
                    <mat-label i18n="A cue for image rendering within the page context (e.g. full screen image, bordered image, etc)">Image Style</mat-label>
                    <mat-select formControlName="imageStyle">
                      <mat-option *ngFor="let style of articleImageStyles" [value]="style.value">
                        {{style.label}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="100%"
                                appearance="outline"
                                *ngIf="usage === 'image'">
                  <mat-label i18n>Label</mat-label>
                  <input matInput
                         formControlName="label"
                         autocomplete="off">
                </mat-form-field>

                <section *ngIf="usage === 'image'">
                  <mat-form-field fxFlex="100%"
                                  appearance="outline">
                    <mat-label i18n>Description</mat-label>
                    <textarea class="gd-upload-image-meta__caption-field"
                              matInput
                              cdkTextareaAutosize
                              #autosize="cdkTextareaAutosize"
                              formControlName="description"
                              cdkAutosizeMinRows="6"
                              cdkAutosizeMaxRows="6"></textarea>
                  </mat-form-field>
                </section>

                <section>
                  <div *ngIf="usage === 'image'"
                       class="gm-upload-image-meta-card__credit-input-wrapper">
                    <mat-form-field class="gm-upload-image-meta-card__credit-input"
                                    appearance="outline">
                        <mat-label i18n>Add Credit</mat-label>
                      <input [formControl]="creditInput"
                             matInput
                             ng-model="creditInput"
                             (keyup.enter)="addCredit(creditInput.value)"
                             autocomplete="off">
                    </mat-form-field>
                    <button mat-icon-button
                            [disabled]="!creditInput.value || !creditInput.value.trim()"
                            color="primary"
                            (click)="addCredit(creditInput.value)">
                      <mat-icon fontSet="fal" fontIcon="fa-plus"></mat-icon>
                    </button>
                  </div>
                  <div *ngIf="usage === 'image'"
                       class="gm-upload-image-meta-card__credits-wrapper">
                    <div #creditItem
                         class="gm-upload-image-meta-card__credit"
                         [class.new-credit]="i === creditsFormArray.controls.length-1 && newCreditAdded"
                         *ngFor="let credit of creditsFormArray.controls; let i = index;">
                      <mat-icon (click)="removeCredit(i)" fontSet="fal" fontIcon="fa-times-circle"></mat-icon>
                      <span>{{ credit.value }}</span>
                    </div>
                  </div>

                </section>

                <gd-tags-autocomplete *ngIf="usage === 'image' || (usage === 'article' && enableTagEditing)"
                                      (tagSelectedEvent)="handleTagSelected($event)"
                                      [group]="'user'">
                </gd-tags-autocomplete>
                <mat-chip-list #tagsChips
                               class="gm-upload-image-meta-card__tags"
                               [class.--embedded-image]="usage === 'gallery' || (usage === 'article' && !enableTagEditing)"
                               selectable="false">

                  <mat-chip *ngFor="let tag of imageTags; let i = index;"
                            class="gm-upload-image-meta-card__chip"
                            [removable]="(usage === 'image' || (usage === 'article'))"
                            (removed)="imageTags.splice(i, 1)"
                            selected
                            [color]="tag.group === 'rekognition' ? 'accent' : 'primary'"
                            [value]="tag.name"
                            [class.--system-tag]="tag.group === 'system'"
                            [class.--premium]="tag?.premium">
                    <span class="gm-upload-image-meta-card__chip-text"
                          [matTooltip]="tag.name"
                          [matTooltipShowDelay]="200">
                      {{ tag.name }}
                    </span>
                    <mat-icon (click)="setPremiumImageTag(tag)"
                              class="gm-upload-image-meta-card__chip-flag-icon"
                              *ngIf="(usage === 'image' || usage === 'article') && tag.group === 'user'"
                              [style.color]="tag.premium ? '#00000099' : '#FFFFFF99'"
                              [fontSet]="tag.premium ? 'fas' : 'fal'"
                              fontIcon="fa-flag-alt"
                              [matTooltip]="getPremiumTagTooltip(tag.premium)">
                    </mat-icon>
                    <mat-icon matChipRemove
                              fontSet="fal"
                              *ngIf="tag.group !== 'system'"
                              fontIcon="fa-times-circle"></mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </form>
            </div>
        <!-- </mat-tab> -->

        <!-- <mat-tab label="Metadata">
          <gd-scroller #imageMetadataScroller>
            <div class="gd-upload-image-meta__metadata">
              <span *ngIf="!image.iptc">
                There's no IPTC metadata for selected image
              </span>
              <ng-container *ngIf="image.iptc">
                <p *ngFor="let entry of image.iptc"
                   [attr.data-label]="entry.key"
                   style="width: 100%">
                  {{ entry.value}}
                </p>
              </ng-container>
            </div>
          </gd-scroller>
        </mat-tab> -->

      <!-- </mat-tab-group> -->

    </div>

  </div>

  <div *ngIf="usage === 'article' && !editingMode && images?.length > 1" style="position: relative">
    <gd-image-slider style="height: 26%;" [selectedImageIndex]="selectedImageIndex" [images]="images" (changeIndex)="selectImage($event)">
    </gd-image-slider>
  </div>

  <div fxLayout="row"
       [fxLayoutAlign]="usage === 'article' ? 'space-between' : 'flex-end'"
       class="gd-upload-image-meta__footer"
       *ngIf="isStandalone">
    <div>
    <button *ngIf="usage === 'article'"
            mat-raised-button
            color="primary"
            i18n="Change the image which is embedded in the content"
            (click)="changeSelectedImage()">
      Change
    </button>
    </div>
    <div>
      <button mat-raised-button
              mat-dialog-close
              i18n
              class="gd-upload-image-meta__cancel-btn">
        Cancel
      </button>

      <button mat-raised-button
              data-cy="gd-upload-image-meta__save"
              color="primary"
              i18n
              (click)="saveImageUploadMeta()">
        Save
      </button>
    </div>

  </div>
</div>
