import { Component, OnInit, Inject, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CustomFormConstructorService } from '../../gpp-shared/widget-configuration/form-constructor/custom-form-constructor.service';
import { BidiService } from '../../core/i18n/bidi.service';

export enum ContentType {
  ARTICLE = 'ARTICLE',
  LR_POST = 'LR_POST',
  COLLECTION = 'COLLECTION'
}

@Component({
  selector: 'gd-collection-embed-widget',
  templateUrl: './collection-embed-widget.component.html',
  styleUrls: ['./collection-embed-widget.component.scss'],
})
export class CollectionEmbedWidgetComponent implements OnInit {
  embeddableForm: UntypedFormGroup = this.formBuilder.group({
    id: ['', Validators.required],
    title: [''],
    updatedBy: [''],
    updatedAt: [''],
    icon: [null],
    colour: [''],
    primaryPreviewField: [''],
    secondaryPreviewField: ['']
  });

  widgetId: number;
  contentType: ContentType = ContentType.ARTICLE;
  is3rdPartyWidget: boolean = false;
  blockId: string;
  dialogInputDataConfiguration = {};
  itemMetaDataFormConfig;
  allowedWidgetTypes: number[] = [];
  isEditingMode: boolean = false;
  tabIndex: number = 0;
  initialValues = null;

  get itemMetaDataControl() {
    return <UntypedFormControl>this.embeddableForm.get('itemMetaData')
  }
  dir$ =  this.bidiService.getEffectiveLocaleDirectionality();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogInputData: any,
    private dialogRef: MatDialogRef<CollectionEmbedWidgetComponent>,
    private formBuilder: UntypedFormBuilder,
    private fc: CustomFormConstructorService,
    private bidiService: BidiService,
  ) {}

  ngOnInit() {
    this.tabIndex = this.dialogInputData.tabIndex;
    this.widgetId = this.dialogInputData.id;
    this.contentType = this.dialogInputData.glideContentType;
    this.is3rdPartyWidget = this.dialogInputData.embedType.split('/')[1] === 'third-party';
    this.dialogInputDataConfiguration = this.dialogInputData.configuration;
    this.allowedWidgetTypes = this.dialogInputData.allowedWidgetTypes;
    this.isEditingMode = !!this.dialogInputData.editingMode;

    this.embeddableForm.patchValue(this.dialogInputData);
    this.embeddableForm.addValidators(widgetLoadingValidator);
    this.embeddableForm.updateValueAndValidity;

    this.prepareItemMetaData();
  }

  prepareItemMetaData() {
    this.embeddableForm.removeControl('itemMetaData');
    this.embeddableForm.removeControl('itemMetaDataConfig');
    this.itemMetaDataFormConfig = this.dialogInputData.itemMetaDataConfig && this.dialogInputData.itemMetaDataConfig.length > 0 ? this.dialogInputData.itemMetaDataConfig : null;
    const initialValues = this.dialogInputData.editingMode ? (this.dialogInputData.itemMetaData || {}) : null;
    // TODO refactor - move responsibility to DynamicWidgetFormComponent
    this.initialValues = initialValues;
    const itemFormGroup = this.fc.createFormGroup(this.itemMetaDataFormConfig, initialValues, { editMode: !!initialValues });
    this.embeddableForm.addControl('itemMetaData', itemFormGroup);
    this.embeddableForm.addControl('itemMetaDataConfig', new UntypedFormControl([]));
    this.embeddableForm.get('itemMetaDataConfig').setValue(this.itemMetaDataFormConfig);
  }

  save() {
    const formModel = this.embeddableForm.value;
    const type = this.is3rdPartyWidget ? 'THIRD_PARTY_WIDGET' : 'SYSTEM_WIDGET';
    return this.dialogRef.close({ ...formModel, type });
  }

  getSaveButtonTooltipMessage() {
    if (!this.embeddableForm.get('id').value) {
      return '';
    }
    if (this.isWidgetCustomDataTaxonomyAllowed() || this.isCollectionItemMetaDataTaxonomyAllowed()) {
      return $localize`Some of the used Taxonomies on custom fields are invalid.`;
    }
    return $localize`Widget custom data form is invalid`
  }

  isWidgetCustomDataTaxonomyAllowed() {
    const configuration = this.embeddableForm.get('configuration') as UntypedFormControl;
    if (!configuration) {
      return false;
    }
    return Object.values(configuration['controls'])
      .some((control: UntypedFormControl) => control.hasError("taxonomyErr"))
  }

  isCollectionItemMetaDataTaxonomyAllowed() {
    const configuration = this.itemMetaDataControl as UntypedFormControl;
    if (!configuration) {
      return false;
    }
    return Object.values(configuration['controls'])
      .some((control: UntypedFormControl) => control.hasError("taxonomyErr"))
  }
}

export function widgetLoadingValidator() {
  return { widgetConfigurationLoading: true };
}
